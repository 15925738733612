import React, { useState } from 'react';
import facebookIcon from '../web2_images/socials/YD_SoMe_icons_fb-white.png';
import instagramIcon from '../web2_images/socials/YD_SoMe_icons_ig-white.png';
import linkedinIcon from '../web2_images/socials/YD_SoMe_icons_Li-white.png';
import youtubeIcon from '../web2_images/socials/YD_SoMe_icons_you-white.png';
import tiktokIcon from '../web2_images/socials/YD_SoMe_icons_tik-white.png';

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const footerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',  // Updated to three equal columns
    width: '100%',
    background: '#08424C',
    color: '#C7F5F9',
    fontFamily: 'Lato, sans-serif',
    padding: '20px 0',
    fontSize: '0.7em',
  };

  const iconStyle = {
    width: '30px',  // Ensures all icons are the same size
    height: '30px',
    margin: '0 10px',
    opacity: '0.8',
    transition: 'opacity 0.3s ease-in-out'  // Add transition for smooth effect
  };
  
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: isModalOpen ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  };

  
  const modalContentStyle = {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '5px',
    width: '80%',
    fontFamily: 'Lato, sans-serif',
    maxWidth: '500px',
    zIndex: 4444,
    color: 'black', // Explicitly set text color to ensure visibility
    textAlign: 'left', // Ensure text is aligned properly
  };


  const closeModal = (e) => {
    if (e.target.id === 'modal') {
      setModalOpen(false);
    }
  };

  return (
    <div style={footerStyle}>
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <div style={{display: 'inline-block', paddingBottom: '15px' }}>
        <strong>Reach Out</strong>
      </div>
      <br />
      <a href="https://www.facebook.com/profile.php?id=61556798181929" target="_blank" rel="noopener noreferrer">
        <img src={facebookIcon} alt="Facebook" style={iconStyle} />
      </a>
      <a href="https://www.instagram.com/yourdevocean/" target="_blank" rel="noopener noreferrer">
        <img src={instagramIcon} alt="Instagram" style={iconStyle} />
      </a>
      <a href="https://www.linkedin.com/company/your-devocean/" target="_blank" rel="noopener noreferrer">
        <img src={linkedinIcon} alt="LinkedIn" style={iconStyle} />
      </a>
      <a href="https://www.youtube.com/@YourDevocean" target="_blank" rel="noopener noreferrer">
        <img src={youtubeIcon} alt="YouTube" style={iconStyle} />
      </a>
      <a href="https://www.tiktok.com/@yourdevocean" target="_blank" rel="noopener noreferrer">
        <img src={tiktokIcon} alt="TikTok" style={iconStyle} />
      </a>
      <br />
      <p>social@yourdevocean.com</p>
    </div>
    <div style={{ textAlign: 'center', padding: '10px', cursor: 'pointer' }}
         onClick={() => setModalOpen(true)}>
      <div style={{ display: 'inline-block', paddingBottom: '5px' }}>
        <strong>Privacy</strong>
      </div>
      <p style={{ cursor: 'pointer' }}>Privacy Policy</p> {/* Clickable */}
    </div>
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <div style={{ display: 'inline-block', paddingBottom: '5px' }}>
        <strong>Your Devocean AB</strong>
      </div>
      <p>Convendum, Vasagatan 16<br></br> 4th floor, Stockholm, Sweden </p>
    </div>

    {/* Modal */}
    <div id="modal" style={modalStyle} onClick={closeModal}>
      <div style={modalContentStyle} onClick={e => e.stopPropagation()}>
        <h2>Privacy Policy</h2>
                
        <p>This Privacy Policy outlines the types of information we <strong>do not collect</strong>, the limited circumstances under which we may access data, and how we handle the information you choose to provide.</p>

        <p><strong>1. Cookies and Tracking Technologies</strong><br/>
        Your Devocean does not use cookies or similar tracking technologies. We do not draft any cookies, nor do we employ any form of system that tracks your activities on our website.</p>

        <p><strong>2. Data Storage and Security</strong><br/>
        Your Devocean does not store any personal data on our servers.</p>

        <p><strong>3. Third-Party Data Collection</strong><br/>
        If you chose to supply an email address, this information will be stored by Typeform.
        If you choose to participate in a survey, the survey will also be powered by TypeForm. The information you provide in these surveys is stored in TypeForm’s databases. Your Devocean may access this information only if you explicitly consent to this during your participation in the survey. Please note that TypeForm has its own privacy policies regarding the storage and handling of your data, which we do not control. We encourage you to review TypeForm’s privacy policy.</p>

        <p><strong>4. Changes to This Policy</strong><br/>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

        <p><strong>6. Contact Us</strong><br/>
        If you have any questions about this Privacy Policy (or other inquiries), please contact us at social@yourdevocean.com.</p>
        <p>Your Devocean, <br/><i>5-22-2024</i> </p>
        <button onClick={() => setModalOpen(false)}>Close</button>
      </div>
    </div>
  </div>
  );
};

export default Footer;
