import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';

import iceImage from '../landing_images/YD_webbg-03.jpg'; // Adjust path as necessary

import styled from 'styled-components';
import logoImage from '../landing_images/YD_logo-turq.png';import Footer from './Footer';  // Import Footer component
import Navbar from './components/Navbar'; 
import HamburgerMenu from './components/HamburgerMenu'; 
import SectionFour from './components/SectionFour'; // Import the new component

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items at the top
  align-items: center;
  height: 100vh;
  background: url(${iceImage}) center center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow:hidden; 
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const OuterContainer = styled.div`
  width: 100%;
  overflow-x: hidden; // Prevent horizontal scrolling
  overflow-y:hidden;
`;

const Logo = styled.img`
  position: absolute;
  top: 25px;
  left: 31px;
  padding: 22px;
  width: 260px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
  }
`;

const isMobile = window.innerWidth <= 768;


const ConsciousConsumers = () => {

  return (
    
    <OuterContainer>
        {isMobile ? (
          <HamburgerMenu currentSection={"impact"} />
        ) : (
          <Navbar current="impact"  buttonColor ={'#87D2DB'} />
        )}
        <Link to="/">
          <Logo src={logoImage} alt="Your Devocean Logo" />
        </Link>
        <SectionFour /> {/* Use the new component */}

    <Footer  />   {/* Using Footer component here */}
    </OuterContainer>
  );
};

export default ConsciousConsumers;
