import React from 'react';
import styled from 'styled-components';
import image1 from '../../landing_images/people/YD_image01.jpg'; // Adjust path as necessary
import image2 from '../../landing_images/people/YD_image02.jpg'; // Adjust path as necessary
import image3 from '../../landing_images/people/YD_image03.jpg'; // Adjust path as necessary
import image4 from '../../landing_images/people/YD_image04.jpg'; // Adjust path as necessary
import image5 from '../../landing_images/people/YD_image05.jpg'; // Adjust path as necessary
import image6 from '../../landing_images/people/YD_logosymbol01.png'; // Adjust path as necessary

const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color:  #08424C;
  padding: 50px 0;
  overflow: 'hidden', // Prevent overflow

`;

const Wrapper = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-right: 25%;
  color: white;
  @media (max-width: 768px) {
    margin-right: 0%;
    width: 100%;
    margin-top: -10%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Lato, sans-serif;
  margin: ${(props) => props.margin || '0'};
  
`;
const DoIt = styled(Text)`
    position: relative;
    z-index: 1;
    font-size: 5rem;
    line-height: 5.2rem;
    top: 100px;
    @media (max-width: 768px) {
        font-size: 3rem;
        line-height: 3.2rem;
    }
`;

const Only = styled(Text)`
    position: relative;
    z-index: 3;
    font-size: 5rem;
    text-align: right;
    line-height: 5.2rem;
    margin-top: -33rem;
    bottom: -3rem;
    left: 10rem;
    max-width: 70%;
    @media (max-width: 768px) {
        font-size: 3.5rem;
        left:5rem;
        top:3.8rem;
        text-align:center;
        line-height: 3.0rem;
        max-width: 65%;
    }
`;

const Ordinary = styled(Text)`
    position: relative;
    z-index: 2;
    font-size: 1.2rem;
    font-weight: 500;
    max-width:25%;
    text-align:left;
    bottom: -5rem;
    left: 34rem;
    @media (max-width: 768px) {
        bottom: -5rem;
        left: 12rem;
        max-width: 45%;
    }
`;

const LogoImage = styled.img`
    position: relative;
    mix-blend-mode: soft-light;
    width: 23rem;
    z-index: 5;
    bottom: 46rem;
    @media (max-width: 768px) {
        width: 18rem;
        bottom: 44.0rem;
    }
`;

const SectionFour = () => {
  return (
    <SectionContainer id="section4">
      <Wrapper >
        <DoIt margin="0 0 50px 40px" style={{textAlign: 'left' }}>We can Do it <br/>Together</DoIt>
        <Row>
          <Image src={image1} width="64%" height="570px" />
          <Image src={image2} width="34%" height="570px" style={{ marginLeft: '2%' }} />
        </Row>
        <Row>
          <Image src={image3} width="100%" height="457px" />
        </Row>
        <Row>
          <Image src={image4} width="49%" height="454px" />
          <Image src={image5} width="49%" height="454px" style={{ marginLeft: '2%' }} />
        </Row>
        <Only margin="20px 0">But we can Only Do it Together</Only>
        <Ordinary margin="20px 0">Ordinary people working together can achieve extraordinary results</Ordinary>
        <LogoImage src={image6}  />
      </Wrapper>
    </SectionContainer>
  );
};

export default SectionFour;
