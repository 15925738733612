import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import logoImage from '../landing_images/YD_logo-turq.png';
import backgroundImage from '../landing_images/YD_webbg_06.jpg';
import image3bt from '../landing_images/logo_3bt.png';
import WaitlistButton from './components/WaitlistButton'; // Assuming you have the FloatingButton component
import Footer from './Footer';  // Import Footer component
import Navbar from './components/Navbar'; // Adjust path as necessary
import HamburgerMenu from './components/HamburgerMenu'; // Adjust path as necessary
import SignupModal from './components/SignupModal'; // Adjust path as necessary
import shorelineImage from '../web2_images/offering_01.jpg'


const OuterContainer = styled.div`
  width: 100%;
  overflow-x: hidden; // Prevent horizontal scrolling
  overflow-y:hidden;
`;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  height: 42vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #08424C;
  background: url(${shorelineImage}) bottom center / cover no-repeat; // Directly use the imported image

  color: #C7F5F9;
  font-family: 'Lato', sans-serif;
  overflow-y:hidden;
  @media (max-width: 768px) {
    height: 42vh;
    justify-content: center;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 25px;
  left: 31px;
  padding: 22px;
  width: 260px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
  }
`;

const CenteredText = styled.div`
  max-width: 800px;
  padding: 20px;
  text-align: center;
  overflow-y:hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    width: 100%; // Ensure it takes the full width on mobile
    box-sizing: border-box; // Include padding in width calculation
  }
`;

const MainText = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  font-style: italic;
  overflow-y:hidden;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    text-align: center; // Ensure the text is centered
    margin-top: -1.2rem;

  }
`;

const SubText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  top: 40vh;
  left: 50%;
  transform: translateX(-50%);
  position:absolute;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    font-size: 0.9rem;
    text-align: center;
    transform: none;
    left: 0;
  }
`;

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 42vh);
  background: url(${backgroundImage}) top center / cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #C7F5F9;
  overflow-y:hidden;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 40px;
    height: calc(100vh - 42vh);
    box-sizing: border-box; // Include padding in width calculation

  }
 
`;
const isMobile = window.innerWidth <= 768;

const IntroducingText = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
   margin-top: 10rem;

  @media (min-width: 768px) {
    margin-top: 40px;
    position:absolute;
    left: 17%;
    font-size: 1.8rem;
  }
`;

const HighlightText = styled.h1`
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.0;
  margin-top: -1rem;
  @media (min-width: 768px) {

    margin: 20px 0;
    position:absolute;
    left: 17%;
    top: 20%;
    text-align: left;
    font-size: 3.2rem;
  }
`;

const Image3BT = styled.img`
  
  @media (max-width: 768px) {
    width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 30px;
  }
  @media (min-width: 768px) {
    position: relative;
    width: 11.5%;
    margin: 20px 0;
    top:4.2%;
    right: 41%;
  }
`;
const MarginHack = styled.div`
  height: 100px;
  width: 100%;
  background-color: #0B202C; // Set the background color to black

`;
const JoinButtonContainer = styled.div`
  margin: 40px 0;
 margin-bottom: 9rem;
 margin-top: 0.4rem;
  position:relative;
  @media (min-width: 768px) {
    position:absolute;
    bottom: 15.1%;
    left: 17%;
    margin: 20px 0;
  }
`;

const CorporatePage = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <OuterContainer>
         {isMobile ? (
          <HamburgerMenu currentSection={"committedCompanies"} />
        ) : (
          <Navbar current="committedCompanies"  buttonColor ={'#87D2DB'} />
        )}
      <TopContainer>
        <Link to="/">
          <Logo src={logoImage} alt="Your Devocean Logo" />
        </Link>
        <CenteredText>
          <MainText>
            "Our platform provides a comprehensive, scalable solution that accelerates progress towards net-zero while enhancing ocean biodiversity."
            By integrating biodiversity into carbon offset strategies, we can amplify environmental & financial  impact up to 10 times & foster a sustainable future together.
          </MainText>
          <SubText>— THE ECONOMIST</SubText>
        </CenteredText>
      </TopContainer>
      <PageContainer>
        <IntroducingText>Your Devocean introducing:</IntroducingText>
        <HighlightText>Blue Biodiversity<br/> & Balance Token — 3Bt</HighlightText>
        <Image3BT src={image3bt} alt="3Bt Logo" />
        <JoinButtonContainer>
          <WaitlistButton onClick={() => setModalOpen(true)} />
          <SignupModal isOpen={isModalOpen} type_form_url= "https://p19teneskuk.typeform.com/to/vDmpQcfZ" onClose={() => setModalOpen(false)} />
        </JoinButtonContainer>
      </PageContainer>
  
    <Footer  />   {/* Using Footer component here */}
    </OuterContainer>
    
  );
};

export default CorporatePage;
