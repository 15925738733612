import React, { useEffect } from 'react';
import Lenis from 'lenis';
import { ReactLenis } from 'lenis/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SmoothScrolling = ({ children }) => {
  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0.1,
      smoothWheel: true,
      smoothTouch: true,
      wheelMultiplier: 1,
      touchMultiplier: 1,
      infinite: false,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // Make Lenis instance globally accessible
    window.lenis = lenis;

    // Update GSAP ScrollTrigger on Lenis scroll
    lenis.on('scroll', ScrollTrigger.update);

    let scrollTimeout;

    const isMobile = window.innerWidth <= 768;

    var timeout = 20; // smaller value is more aggressive snap
    if (isMobile) {
      timeout = 100;
    }
    lenis.on('scroll', () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        snapToNearestSection(lenis);
      }, timeout); // Adjust timeout duration as needed
    });

    return () => {
      lenis.destroy();
    };
  }, []);

  const snapToNearestSection = (lenis) => {
    const sections = document.querySelectorAll('section');
    const scrollY = window.scrollY || window.pageYOffset;

    let closestSection = null;
    let minDistance = Infinity;

    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const distance = Math.abs(rect.top);

      if (distance < minDistance) {
        minDistance = distance;
        closestSection = section;
      }
    });
    var snapHeightThreshold = 9; // larger value means smaller snap height
    
    if (!closestSection){
      return;
    }

    if (closestSection.id == "section4") { // next to footer
      snapHeightThreshold = 8;
    }
    if (closestSection.id == "section45") { // next to footer
      snapHeightThreshold = 8;
    }
    if (closestSection.id == "team1") { // next to footer
      snapHeightThreshold = 8;
    }
    if (closestSection.id == "survey1") { // next to footer
      snapHeightThreshold = 8;
    }
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      return;
      snapHeightThreshold = 0.5;
      if (closestSection.id == "section4"){
        snapHeightThreshold = 0.2;

      }
    }

    if (closestSection && minDistance < window.innerHeight / snapHeightThreshold) { // Adjust threshold as needed
      lenis.scrollTo(closestSection, { duration: 1.5, easing: (t) => t * (2 - t) }); // Smooth scroll to the section
    }
  };

  return (
    <ReactLenis root>
      {children}
    </ReactLenis>
  );
};

export default SmoothScrolling;
