import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  z-index: 500;
  padding: 15px 45px;
  background-color: #87D2DB;
  letter-spacing: 0.05em;
  font-weight: 590;
  font-family: Lato, sans-serif;
  font-size: 22px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transform: ${(props) => (props.isVisible ? 'translateY(0)' : 'translateY(100vh)')};
  transition: ${(props) =>
    props.animateOnLoad
      ? 'opacity 2.5s cubic-bezier(0.8, 0, 0.4, 1), transform 2.5s cubic-bezier(0.8, 0, 0.4, 1), background-color 0.5s ease'
      : 'none'};

  &:hover {
    background-color: #285C70;
  }
`;

const WaitlistButton = ({ onClick, animateOnLoad = false }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (animateOnLoad) {
      setTimeout(() => setIsVisible(true), 0); // Ensure the animation starts after component mounts
    } else {
      setIsVisible(true);
    }
  }, [animateOnLoad]);

  return (
    <Button isVisible={isVisible} animateOnLoad={animateOnLoad} onClick={onClick}>
      JOIN WAITLIST
    </Button>
  );
};

export default WaitlistButton;
