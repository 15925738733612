import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import screen from '../../web2_images/app_screenshots/offering_screen00.png'; 
import screen1 from '../../web2_images/app_screenshots/offering_screen01.png';
import screen2 from '../../web2_images/app_screenshots/offering_screen02.png';
import screen3 from '../../web2_images/app_screenshots/offering_screen03.png';
import screen4 from '../../web2_images/app_screenshots/offering_screen04.png';
import screen5 from '../../web2_images/app_screenshots/offering_screen05.png';
import screen6 from '../../web2_images/app_screenshots/offering_screen06.png';
import screen7 from '../../web2_images/app_screenshots/offering_screen07.png';
import screen8 from '../../web2_images/app_screenshots/offering_screen08.png';
import screen9 from '../../web2_images/app_screenshots/offering_screen09.png';

import landing4 from '../../web2_images/solution_04.jpg';
import { gsap } from 'gsap';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1400px;
  width: 100%;
  background-color:#08424C;
  background: url(${landing4}) center center / cover no-repeat;
  text-align: center;
  overflow: hidden; 
  @media (max-width: 768px) {
    height: 1100px;
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  width: 30%;
  padding: 2rem;
  color: white;
  font-family: 'Lato', sans-serif;
  text-align: left;

  @media (max-width: 768px) {
    width: 60%;
    padding: 1rem;
    margin-top: ${(props) => (props.hasVisibleContent ? '-25rem' : '-6rem')}; // Adjust the values as necessary
  }

  @media (min-width: 769px) {
    margin-top: ${(props) => (props.hasVisibleContent ? '-22rem' : '5rem')}; // Adjust the values as necessary
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #87D2DB;
  margin-bottom: 1rem;
  font-family: 'Lato', sans-serif;
  opacity: 1;
  font-weight: 900;

  @media (min-width: 768px) {
    font-size: 1.9rem;
    letter-spacing: 0.55rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  opacity: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
  @media (min-width: 768px) {
    line-height: 1.3;
  }
`;

const ScreenWrapper = styled.div`
  position: relative;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 50%;
    margin-top: 0.0rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-top: ${(props) => (props.hasVisibleContent ? '-22rem' : '5rem')}; // Adjust the values as necessary
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: ${(props) => (props.hasVisibleContent ? '-2rem' : '0.2rem')}; // Adjust the values as necessary
    width: 100%;
  }
`;

const ScreenImg = styled.img`
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    width: 120%;
  }
`;

const InnerImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Adjust based on the frame size */
  opacity: 0;
  @media (max-width: 768px) {
    width: 120%;
  }
`;

const PartnerPageThree = ({ hasVisibleContent }) => {
  const images = [screen1, screen2, screen3, screen4, screen5, screen6, screen7, screen8, screen9];
  const imageRefs = useRef(images.map(() => React.createRef()));
  const textRef = useRef(null);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentText, setCurrentText] = useState('UNITE');
  const textOptions = ['UNITE', 'EMPOWER', 'IMPACT'];

  const getTextForImageIndex = (index) => {
    if (index >= 0 && index <= 2) {
      return textOptions[0]; // Unite
    } else if (index >= 3 && index <= 5) {
      return textOptions[1]; // Empower
    } else if (index >= 6 && index <= 8) {
      return textOptions[2]; // Impact
    }
  };

  useEffect(() => {
    const imageInterval = setInterval(() => {
      const nextIndex = (currentImageIndex + 1) % images.length;
      const currentImage = imageRefs.current[currentImageIndex]?.current;
      const nextImage = imageRefs.current[nextIndex]?.current;

      if (currentImage && nextImage) {
        const newText = getTextForImageIndex(nextIndex);
        const timeline = gsap.timeline();

        timeline.to(currentImage, { opacity: 0, duration: 0.3 });
        
        if (newText !== currentText) {
          timeline.to(textRef.current, { opacity: 0, duration: 0.3, onComplete: () => {
            setCurrentText(newText);
          }});
        }

        timeline.to(nextImage, { opacity: 1, duration: 0.3 });
        
        if (newText !== currentText) {
          timeline.to(textRef.current, { opacity: 1, duration: 0.3 });
        }

        setCurrentImageIndex(nextIndex);
      }
    }, 3000); // Change image every 3 seconds

    return () => {
      clearInterval(imageInterval);
    };
  }, [currentImageIndex, images.length, currentText]);

  return (
    <SectionContainer>
      <TextContainer hasVisibleContent={hasVisibleContent}>
        <Title ref={textRef}>{currentText}</Title>
        <Description>Our app makes ocean regeneration accessible to all. Every activity in one place, connecting hands & funds with marine projects.</Description>
      </TextContainer>
      <ScreenWrapper>
        <ImageContainer hasVisibleContent={hasVisibleContent}>
          <ScreenImg src={screen} alt="screen" />
          {images.map((image, index) => (
            <InnerImage
              key={index}
              ref={imageRefs.current[index]}
              src={image}
              alt={`inner screen ${index}`}
              style={{ opacity: index === 0 ? 1 : 0 }}
            />
          ))}
        </ImageContainer>
      </ScreenWrapper>
    </SectionContainer>
  );
};

export default PartnerPageThree;
