import React, { useState } from 'react';
import styled from 'styled-components';

import iceImage from '../../web2_images/offering_03.jpg'; // Adjust path as necessary

import flywheel from '../../web2_images/solution_flywheel.png';
import flywheel2 from '../../web2_images/solution_flywheel-mob.png';

import icon_oo from '../../web2_images/icon_oo.png';
import icon_concon from '../../web2_images/icon_concon.png';
import icon_comcom from '../../web2_images/icon_comcom.png';
import fold_downarrow from '../../web2_images/fold_down-arrow.png';
import PartnerPageThree from './PartnerPageThree';


const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items at the top
  align-items: center;
  height: 1040px;
  background: url(${iceImage}) center center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 0 20px;
    height: 950px;
  }
`;


const SectionContainerDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start; // Align items at the top
  align-items: center;
  height: 1040px;
  background: url(${iceImage}) center center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 0 20px;
    height: 950px;
  }
`;


const Statement = styled.div`
  margin-top: 6rem;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  width: 50%;
  @media (max-width: 768px) {
    font-size: 1.0rem;
    width: 76%;
  }
`;

const isMobile = window.innerWidth <= 768;

const Flywheel = styled.img`
  width: 50%;
  margin-top: 2rem;
  @media (max-width: 768px) {
    width: 370px;
  }
  @media (min-width: 768px) {
    margin-top: 6%;
  }
`;

const FlywheelDesktop = styled.img`
  width: 40%;
  position:relative;
  left: -200px;
  margin-bottom: -100px;

  @media (max-width: 768px) {
    width: 370px;
  }
  @media (min-width: 768px) {
    margin-top: -17%;
  }
`;

const Mission = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  color: #C7F5F9;
  font-weight: 400;
  letter-spacing: 0.3rem;
  text-align: left;
`;

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 260px;
  margin-bottom:200px;
`

const TextContainer = styled.div`
  display: flex;
  height: auto; /* Adjust height to auto if necessary */
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: #08424C;
  font-family: Lato, sans-serif;
  max-width: auto;
  overflow: visible; /* Ensure overflow is visible */
  position: relative; /* Ensure correct positioning */

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -10rem;
    height: auto;
    align-items: center;
  }

  @media (min-width: 768px) {
     margin-top: -2rem;

  }
`;

const Item = styled.div`
  width: 20rem;
  height: 100%;
  margin-bottom: 5rem;
  padding: 2.2rem;
  color: white; // Ensure text color is visible
  text-align: left;

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

const Element = styled.img`
  width: 39%;
`;

const Tit = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center; // Center items horizontally
 justify-content: center; // Center items vertically

`;

const Text = styled.div`
  font-family: 'Lato', sans-serif;
  margin-top: 1rem; // Add margin to create space
  text-align: center; // Center align text
  font-size:1.3rem;
  color: #C7F5F9;
  font-weight:bold;
  @media (max-width: 768px) {
    font-size:1.1rem;
  }
`;

const Subtext = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color:#C7F5F9;
  font-size:1.2rem;
  margin-top: 0.5rem; // Add margin to create space
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const FolddownArrow = styled.img`
  margin-top: 1.0rem;
  padding: 0.5rem;
  width: 7%;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.rotated ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

const El = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center; // Center items horizontally
  align-items: center;
  width: 100%; // Ensure it takes full width
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  height: ${(props) => (props.hasVisibleContent ? 'auto' : '1200px')};
  flex-direction: column;
  align-items: center;
  transition: height 0.3s ease;
  overflow: visible; /* Ensure overflow is visible */
  @media (max-width: 768px) {
    height: auto;
  }
`;


const StatementDesktop = styled.div`
  width: 50%;
  text-align: left;
  color: #C7F5F9;

`
const StatementDesktopA = styled.div`
  width: 50%;
  text-align: left;
  color: #87D2DB;

`
const Word = styled.div`
  margin-bottom: -7px;
`


const PartnerPageTwo = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);


  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleToggle2 = () => {
    setIsVisible2(!isVisible2);
  };

  const handleToggle3 = () => {
    setIsVisible3(!isVisible3);
  };
  const hasVisibleContent = isVisible || isVisible2 || isVisible3;
  return (
    <div>
      {isMobile &&     
        <SectionContainer>
          <Statement>
            Our platform empowers stakeholders by connecting them with marine projects that deliver measurable environmental impact and drive ocean regeneration
          </Statement>
          <Flywheel src={isMobile ? flywheel2 : flywheel}></Flywheel>
        </SectionContainer>
      }
       {!isMobile &&     
        <SectionContainerDesktop>
          <MissionContainer>
            <Mission>
              <Word>UNITE</Word> <br/>
              <Word>EMPOWER</Word> <br/> 
              <Word>IMPACT</Word><br/>
            </Mission>
            <StatementDesktopA>
             <i> Making Ocean regeneration easy, accessible & valuable to all, bringing hands and funds - together alive & thriving.</i> <br/><br/>
            </StatementDesktopA>
            <StatementDesktop>
              Our platform empowers stakeholders by connecting them with marine projects that deliver measurable environmental impact and drive ocean regeneration
            </StatementDesktop>
          </MissionContainer>
          <FlywheelDesktop src={isMobile ? flywheel2 : flywheel}></FlywheelDesktop>
        </SectionContainerDesktop>
      }
      
      <ContentWrapper hasVisibleContent={hasVisibleContent}>
        <TextContainer hasVisibleContent={hasVisibleContent}>
            <Item>
            <Tit>
                <Element src={icon_oo}></Element>
                <El onClick={handleToggle3}>
                <Text>OCEAN ORGANISATIONS</Text>
                <FolddownArrow src={fold_downarrow} rotated={isVisible3} />
                </El>
            </Tit>
            <Subtext visible={isVisible3}>
                <span style ={{fontWeight: 'bold'}}>Unite & Empower your Mission.<br/> </span> 
                <span style ={{fontSize: '1.0rem'}}>Our platform unifies ocean organisations by providing essential funding, manpower, and collaborative tools to amplify collective impact for oceans. </span>
               <ul style ={{fontSize: '1.0rem'}}>
                <li> Access diverse funding sources to kickstart and sustain projects. </li>
                <li>Connect with skilled volunteers, and access essential project management tools. </li>
                <li> Amplify your efforts to support & empower communities locally. </li>
                </ul>
                <span style ={{fontSize: '1.0rem'}}>Join a powerful network of like-minded organisations & people to gain recognition and support for your work. Benefit from a wealth of shared expertise, resources and best practices. </span>
            </Subtext>
            </Item>
            <Item>
            <Tit>
                <Element src={icon_concon}></Element>
                <El onClick={handleToggle}>
                <Text>COMMITTED COMPANIES</Text>
                <FolddownArrow src={fold_downarrow} rotated={isVisible} />
                </El>
            </Tit>
            <Subtext visible={isVisible}>
                <span style ={{fontWeight: 'bold'}}> Invest in actions for ocean impact. </span>
                <br/>
                <span style ={{fontSize: '1.0rem'}}> Our platform provides companies with transparent, de-risked investment opportunities in ocean regeneration projects, ensuring responsible impact and enhanced brand recognition.  </span>
                <ul style ={{fontSize: '1.0rem'}}>
                <li>Multiple sponsors per project for secure, balanced, diversified portfolios. </li>
                <li>Real-time impact tracking and reporting. </li>
                <li>Align your brand with genuine impact for oceans. </li>
                </ul>
                <span style ={{fontSize: '1.0rem'}}>Stand out as a leader in environmental responsibility to engage employees and customers with authentic CSR initiatives, and a solution that supports sustainability strategies.</span>
            </Subtext>
            </Item>
            <Item>
            <Tit>
                <Element src={icon_comcom}></Element>
                <El onClick={handleToggle2}>
                <Text>CONSCIOUS COMMUNITY</Text>
                <FolddownArrow src={fold_downarrow} rotated={isVisible2} />
                </El>
            </Tit>
            <Subtext visible={isVisible2}>
                <span style ={{fontWeight: 'bold'}}> Transform your passion into purpose. </span> 
                <span style ={{fontSize: '1.0rem'}}> Our platform connects individuals to volunteer opportunities and educational resources, enabling active participation in ocean conservation to make a real difference. </span>
                <ul style ={{fontSize: '1.0rem'}}>
                <li> Connect with causes that align with your values and activate your heart.</li>
                <li> Participate in hands-on activities that make a difference.</li>
                <li> Access a wealth of information to deepen your understanding of ocean issues and gain skills to tackle them. </li>
                </ul>
                <span style ={{fontSize: '1.0rem'}}> Join a powerful movement of like-minded & professionals for ocean regeneration and a connected life. Use our platform to stay informed, learn & inspire action and awareness.</span>
            </Subtext>
            </Item>
        </TextContainer >
        <PartnerPageThree hasVisibleContent={hasVisibleContent} />
      </ContentWrapper>
    </div>
  );
};

export default PartnerPageTwo;
