import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import impact from '../../web2_images/impact_04.jpg';

const ImpactPageThree = () => {
  const [activeTab, setActiveTab] = useState('CARBON SEQUESTRATION');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tabContent = {
    'CARBON SEQUESTRATION': {
      challenge: 'Rising carbon emissions are a major contributor to global climate change. Oceans, particularly blue carbon ecosystems like mangroves, seagrasses, and salt marshes, play a critical role in capturing and storing carbon.',
      approach: `<strong>Blue carbon projects:</strong> We facilitate investments in blue carbon projects that restore and protect these vital ecosystems.<br><br><strong>Verified carbon credits:</strong> Through blockchain technology, we ensure transparent tracking and certification of carbon credits generated by these projects.<br><br>`,
      impact: `<strong>Carbon capture:</strong> Significant reduction in atmospheric carbon through effective sequestration.<br><br><strong>Climate mitigation:</strong> Contributing to global efforts to combat climate change by supporting sustainable carbon sinks.<br><br>`,
    },
    'BIODIVERSITY RESTORATION': {
      challenge: 'Marine biodiversity is under threat from overfishing, habitat destruction, carbon dioxide-, chemical- and plastic pollution. Restoring diverse marine ecosystems is essential for the health of the planet.',
      approach: `<strong>Ecological projects:</strong> Fund projects aimed at restoring coral reefs, oyster beds, and other critical habitats.<br><br><strong>Collaborative efforts:</strong> Partner with NGOs and research institutions to implement science-based restoration techniques.<br><br>`,
      impact: `<strong>Ecosystem health:</strong> Enhanced resilience and diversity of marine ecosystems.<br><br><strong>Species protection:</strong> Preservation of endangered species and the overall balance of marine life.<br><br>`,
    },
    'CLEAN OCEANS': {
      challenge: 'Ocean pollution, particularly plastic waste, is a growing environmental crisis affecting marine life and human health.',
      approach: `<strong>Cleanup initiatives:</strong> Fund and sponsor organized beach cleanups, river & lake clean-ups incl. chemical waste intervention, waste removal from marine environments, and pollution prevention projects.<br><br><strong>Empower the masses:</strong> Make it easy for conscious individuals to find and partake in activities around the world.<br><br><strong>Education and advocacy:</strong> Raise awareness and promote sustainable practices to reduce plastic use and marine pollution.<br><br>`,
      impact: `<strong>Pollution reduction:</strong> Cleaner marine environments free from harmful waste.<br><br><strong>Public awareness:</strong> Increased global consciousness about ocean conservation and sustainable living.<br><br>`,
    },
    'COASTAL POPULATIONS': {
      challenge: 'Coastal communities often depend on marine resources for their livelihoods but face economic and environmental challenges.',
      approach: `<strong>Community projects:</strong> Directly fund projects that support local communities, such as sustainable fishing practices, eco-tourism, and cultural preservation.<br><br><strong>Employment and training:</strong> Funds provide job opportunities and the platform provides training in ocean conservation and sustainable resource management.<br><br>`,
      impact: `<strong>Economic growth:</strong> Improved livelihoods and economic stability for coastal communities.<br><br><strong>Cultural preservation:</strong> Support for local traditions and sustainable development that respects cultural heritage.<br><br>`,
    },
    'CONSCIOUS COMMUNITY': {
      challenge: 'Creating a global movement for ocean conservation requires widespread awareness and active participation from individuals.',
      approach: `<strong>Volunteer engagement:</strong> Offer an app that connects individuals to volunteer opportunities in ocean regeneration projects.<br><br><strong>Community building:</strong> Foster a network of conscious individuals committed to environmental stewardship.<br><br>`,
      impact: `<strong>Global participation:</strong> Empower individuals to take direct action in ocean conservation efforts.<br><br><strong>Behavioral change:</strong> Encourage sustainable practices and a deeper connection to the ocean among participants.<br><br>`,
    }
  };

  const tabs = Object.keys(tabContent);

  const nextTab = () => {
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex]);
  };

  const prevTab = () => {
    const currentIndex = tabs.indexOf(activeTab);
    const prevIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    setActiveTab(tabs[prevIndex]);
  };

  return (
    <Container>
      <Header>
        <HeaderText>
         OUR IMPACT FRAMEWORK
        </HeaderText>
        <SubHeaderText>
            Deep Dive
        </SubHeaderText>
        <Navbar>
        {isMobile ? (
          <>
            <NavItem onClick={prevTab}>{'<'}</NavItem>
            <NavItem active>{activeTab}</NavItem>
            <NavItem onClick={nextTab}>{'>'}</NavItem>
          </>
        ) : (
          tabs.map(tab => (
            <NavItem key={tab} active={activeTab === tab} onClick={() => setActiveTab(tab)}>
              {tab}
            </NavItem>
          ))
        )}
      </Navbar>
      </Header>
     
    <Content isMobile={isMobile}>
      <ColumnsWrapper isMobile={isMobile}>
        {isMobile ? (
          <Column>
            <Section>
              <Label>CHALLENGE</Label>
              <TextBox>{tabContent[activeTab].challenge}</TextBox>
            </Section>
            <Section>
              <Label>APPROACH</Label>
              <TextBox dangerouslySetInnerHTML={{ __html: tabContent[activeTab].approach }} />
            </Section>
            <Section>
              <Label >IMPACT</Label>
              <TextBox dangerouslySetInnerHTML={{ __html: tabContent[activeTab].impact }} />
            </Section>
          </Column>
        ) : (
          <>
            <LeftColumn>
              <Section>
                <Label>CHALLENGE</Label>
                <TextBox>{tabContent[activeTab].challenge}</TextBox>
              </Section>
              <Section>
                <Label>APPROACH</Label>
                <TextBox dangerouslySetInnerHTML={{ __html: tabContent[activeTab].approach }} />
              </Section>
            </LeftColumn>
            <RightColumn>
              <Section style ={{marginLeft:'0px'}}>
                <Label >IMPACT</Label>
                <TextBox dangerouslySetInnerHTML={{ __html: tabContent[activeTab].impact }} />
              </Section>
            </RightColumn>
          </>
        )}
      </ColumnsWrapper>
      <Pagination>
        {tabs.map(tab => (
          <Dot key={tab} active={activeTab === tab} onClick={() => setActiveTab(tab)} />
        ))}
      </Pagination>
    </Content>
    </Container>
  );
};

export default ImpactPageThree;

// Styled Components
const Container = styled.div`
  font-family: 'Lato', sans-serif;
`;



const HeaderText = styled.div`
  color: #C7F5F9;
  margin-top: 50px;
  font-weight:900;
  font-size:2.9rem;
`;
const SubHeaderText = styled.div`
    font-weight:300;
    font-size:1.3rem;
    color: #C7F5F9;
`

const Header = styled.div`
  background-color: #08424C;
  text-align: center;
  font-size: 1.7em;
  padding: 20px;
  font-weight: bold;
`;

const Navbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
  margin-top: 55px;
  margin-bottom: -20px;
`;

const NavItem = styled.div`
  color: ${props => (props.active ? '#C7F5F9' : '#87D2DB')};
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-family: 'Lato', sans-serif; 
  font-size: 0.55em;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #C7F5F9;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 76vh;
  background: url(${impact}) center center / cover no-repeat;
  font-family: 'Lato', sans-serif;

  ${props => props.isMobile && `
    flex-direction: column;
    align-items: center;
    height: auto;
  `}
`;

const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex: 1;

  ${props => props.isMobile && `
    flex-direction: column;
    align-items: center;
  `}
`;


const Column = styled.div`
  width: 100%;
`;

const LeftColumn = styled.div`
  width: 45%;
`;

const RightColumn = styled.div`
  width: 45%;
`;

const Section = styled.div`
  margin-bottom: 20px;
  margin-top: 80px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 90%;
  }
  @media (min-width: 768px) {
    margin-left:150px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  opacity:1;
  font-weight: bold;
  font-size: 0.7rem;
  letter-spacing: 0.2rem;

  color: #C7F5F9;
`;

const TextBox = styled.div`
  width: 80%;
  padding: 20px;
  background-color: rgba(199, 245, 249, 0.3); /* Use rgba to set semi-transparent background */

  box-sizing: border-box;
  color: white;
  border-radius: 8px; /* Add this line for rounded edges */

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Pagination = styled.div`
  text-align: center;
  padding: 20px;
`;

const Dot = styled.span`
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: ${props => (props.active ? '#C7F5F9;' : 'none')};
  border: 1px solid #C7F5F9;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
`;
