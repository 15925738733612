import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import TypeformEmbed from '../TypeformEmbed'; // Import your Typeform component
import surveyImage from '../../web2_images/survey_01.jpg';

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  width: 100%;

  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  background-color: #29606D;
`;

const ImageContainer = styled.div`
  width: 39%;
  height: 100%;
  position: absolute;

  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  top: 0;
  left: 0;
  margin-left:-2rem;
  z-index: 1; /* Ensures the image is behind the content but on top of the background */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none; /* Hide the image on mobile */
  }
`;


const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 2; /* Ensures the content is above the image but behind the form */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 2rem;
    order: 2; /* Ensure buttons are displayed after the text on mobile */
  }
`;

const Button = styled.button`
  border: 4px solid #C7F5F9; /* Same turquoise color */
  background: ${(props) => (props.selected ? '#87D2DB' : 'transparent')}; /* Turquoise background if selected */
  color: ${(props) => (props.selected ? 'white' : '#C7F5F9')}; /* White text if selected */
  padding: 0.2rem 3.4rem;
  text-align: center;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0.7rem;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 900;
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')}; /* Default cursor if selected */
  border-radius: 0.5rem;

  &:hover {
    background: ${(props) => (props.selected ? '#87D2DB' : '#87D2DB')}; /* Turquoise background on hover */
    color: white; /* White text on hover */
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  color: #C7F5F9;
  max-width: 600px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 1rem;
    padding: 0.3rem;
    text-align: center;
    align-items: center; /* Center align the text */
    order: 1; /* Ensure text is displayed before the buttons on mobile */
  }
`;

const Headline = styled.h1`
  font-size: 2rem;
  margin-top: -1.7rem;
  margin-bottom: -0.2rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin:0.2rem;
  }
`;

const CustomText = styled.span`
  display: ${(props) => (props.visible ? 'inline' : 'none')};
`;
const SurveyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem; /* Adjusted margin for spacing between content and form */
  z-index:3;
  @media (max-width: 768px) {
    margin-top: 0;
    position: absolute; /* Added for overlay on mobile */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3; /* Ensure it is above other content */
  }
`;

const SurveyOne = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyParam = queryParams.get('survey');
  const uidParam = queryParams.get('uid');

  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const surveyURLs = {
    ocean_org: 'https://p19teneskuk.typeform.com/Org-survey',
    company: 'https://p19teneskuk.typeform.com/company-survey',
    individual: 'https://p19teneskuk.typeform.com/user-survey',
  };

  useEffect(() => {
    if (surveyParam) {
      let surveyURL = surveyURLs[surveyParam];
      if (uidParam) {
        surveyURL += `#user_id=${uidParam}`;
      }
      setCurrentSurvey(surveyURL);
      setSelectedSurvey(surveyParam);
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [surveyParam, uidParam]);

  const handleButtonClick = (surveyType) => {
    let surveyURL = surveyURLs[surveyType];
    if (uidParam) {
      surveyURL += `#user_id=${uidParam}`;
    }
    setCurrentSurvey(surveyURL);
    setSelectedSurvey(surveyType);
  };

  return (
    <SectionContainer id="survey1">
      {isMobile ? (
        <>
          <ContentContainer>
            <TextContainer>
              <Headline>Help Us Help You</Headline>
              <Paragraph>
                <CustomText visible={!selectedSurvey}>
                  Please select the appropriate survey to help us gather relevant information.&nbsp;

                </CustomText>
                 Your input will be used to shape the development of a digital platform that enables global ocean regeneration.
              </Paragraph>
            </TextContainer>
            <ButtonContainer>
              <Button
                onClick={() => handleButtonClick('ocean_org')}
                selected={selectedSurvey === 'ocean_org'}
                disabled={selectedSurvey === 'ocean_org'}
              >
                OCEAN ORG SURVEY
              </Button>
              <Button
                onClick={() => handleButtonClick('company')}
                selected={selectedSurvey === 'company'}
                disabled={selectedSurvey === 'company'}
              >
                CORPORATE SURVEY
              </Button>
              <Button
                onClick={() => handleButtonClick('individual')}
                selected={selectedSurvey === 'individual'}
                disabled={selectedSurvey === 'individual'}
              >
                USER SURVEY
              </Button>
            </ButtonContainer>
          </ContentContainer>
          {currentSurvey && (
            <SurveyContainer>
              <TypeformEmbed
                url={currentSurvey}
                style={{ width: '100%', height: 'auto', minHeight: '600px', marginBottom: '20px' }}
              />
            </SurveyContainer>
          )}
        </>
      ) : (
        <>
         <ImageContainer>
            <img src={surveyImage} alt="Survey background" />
          </ImageContainer>
          <ContentContainer>
            <ButtonContainer>
              <Button
                onClick={() => handleButtonClick('ocean_org')}
                selected={selectedSurvey === 'ocean_org'}
                disabled={selectedSurvey === 'ocean_org'}
              >
                OCEAN ORG SURVEY
              </Button>
              <Button
                onClick={() => handleButtonClick('company')}
                selected={selectedSurvey === 'company'}
                disabled={selectedSurvey === 'company'}
              >
                CORPORATE SURVEY
              </Button>
              <Button
                onClick={() => handleButtonClick('individual')}
                selected={selectedSurvey === 'individual'}
                disabled={selectedSurvey === 'individual'}
              >
                USER SURVEY
              </Button>
            </ButtonContainer>
            <TextContainer>
              <Headline>Help Us Help You</Headline>
              <Paragraph>
                <CustomText visible={!selectedSurvey}>
                  Please select the appropriate survey to help us gather relevant information.&nbsp; 
                </CustomText>
                Your input will be used to shape the development of a digital platform that enables global ocean regeneration.
              </Paragraph>
            </TextContainer>
          </ContentContainer>
          {currentSurvey && (
            <SurveyContainer>
              <TypeformEmbed
                url={currentSurvey}
                style={{ width: '100%', height: 'auto', minHeight: '500px', marginBottom: '20px' }}
              />
            </SurveyContainer>
          )}
        </>
      )}
    </SectionContainer>
  );
};

export default SurveyOne;
