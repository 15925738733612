import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import iceImage from '../../web2_images/landing_scope-illu.png';
import handwritten from '../../web2_images/landing_hand-bridge.png';
import mobileImage1 from '../../web2_images/landing_scope-illu-mob1.png';
import mobileImage2 from '../../web2_images/landing_scope-illu-mob2.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align items at the top
  align-items: flex-start;
  height: 120vh;
  width: 100%;
  position: relative;
  text-align: left;
  color: #C7F5F9;
  overflow: hidden; 
  padding-left: 20px;
  margin-bottom: -200px;

  @media (max-width: 768px) {
    padding-left: 0;
    height: auto;
    align-items: center;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 90px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  font-weight: 200;
  max-width: 600px;
  color: #C7F5F9;
  font-size: 1.2rem;

  h2 {
    color: #C7F5F9;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    color: #C7F5F9;
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    width: 80%;
    text-align: center;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  right: 19%;
  top: 51%;
  transform: translateY(-50%);
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
    position: static;
    transform: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    width: 86%;
    height: auto;
    margin-right: 8%;
  }
`;

const Section = styled.div`
  background-color: #0A252B;
  position: relative;
  width: 100%;
`;

const NetZero = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 5%;
`;

const NetText = styled.div`
  width: 40%;
  margin-left: 15%;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    margin-left: 0%;
    width:75%;
  }
`;

const Balance = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 47%;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const WeText = styled.div`
  width: 40%;
  margin-left: 15%;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    margin-left: 0%;
    margin-bottom: 30px;

    width:60%;
  }
`;

const FinalTextContainer = styled.div`
  background-color: #08424C;
  margin-top: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    font-size: 1.0rem;
    margin-top: 260px;
  }
`;

const FinalTextContainerOne = styled.div`
  background-color: #0A252B;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    font-size: 1.0rem;
  }
`;


const FinalText = styled.div`
  max-width: 100%;
  font-size: 1.0rem;
  color: #C7F5F9;
  margin-top: 60px;
  margin-bottom: 00px;
  width:45%;
  font-weight: 200;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    width: 80%;
    margin-top: 60px;
    margin-bottom: 00px;
  }
`;
// Text2 should inherit FinalText
const Text2 = styled(FinalText)`
    margin-top: 0;
    margin-bottom: 60px;
`

const HandwrittenImage = styled.img`
  width: 30%;
  height: auto;

  @media (max-width: 768px) {
    width: 60%;
  }
`;
const LandingFourFive = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Your GSAP animations here
  }, []);

  return (
    <Section id="section45">
      <FinalTextContainerOne>
        <HandwrittenImage src={handwritten} alt="Handwritten text" />
      </FinalTextContainerOne>
      <SectionContainer>
        <HeaderRow>
          <NetZero>PATH TO NET-ZERO</NetZero>
          <Balance>...and to climate balance</Balance>
        </HeaderRow>
        <TextContainer>
          <NetText>To reach Net-Zero, most companies will <b>have to</b> go beyond scope 1,2,3 by investing in carbon absorption initiatives.<br/><br/></NetText>
          <WeText>This is where we come in.</WeText>
        </TextContainer>
        <ImageContainer>
          {isMobile ? (
            <>
              <Image src={mobileImage1} alt="Illustration for mobile 1" />
              <Image src={mobileImage2}style ={{marginTop: "70px"}} alt="Illustration for mobile 2" />
            </>
          ) : (
            <Image src={iceImage} alt="Illustration" />
          )}
        </ImageContainer>
      </SectionContainer>
      <FinalTextContainer>
        <FinalText>
        The ocean is the best system we have to capture and store CO₂. </FinalText>
        <Text2>Investing in ocean solutions makes the ocean part of its own cure - <b>and you can be too.</b></Text2>
      </FinalTextContainer>
    </Section>
  );
};

export default LandingFourFive;
