import { createGlobalStyle, css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInStyle = css`
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;
`;

export const fadeInStyleOne = css`
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;
  animation-delay: 1s; // First element, no delay
`;

export const fadeInStyleEquals = css`
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;
  animation-delay: 2.5s; // Second element, delay for 1 second
`;

export const fadeInStyleCars = css`
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;
  animation-delay: 3s; // Third element, delay for 2 seconds
`;

export const GlobalStyle = createGlobalStyle`
  /* Add global styles here if needed */
`;
