import React, { useState, useEffect  } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar'; // Adjust path as necessary
import HamburgerMenu from './components/HamburgerMenu'; // Adjust path as necessary

import backgroundOceanImage from '../images/YD_webbg-orgs.jpg';
import textImage from '../landing_images/YD_logo-turq.png';
import Footer from './Footer';  // Import Footer component
import TypeformEmbed from './TypeformEmbed'; // Import your Typeform component

const SurveyPage = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredText, setHoveredText] = useState("");
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentSection, setCurrentSection] = useState('section1');

  const queryParams = new URLSearchParams(location.search);
  const formOpen = queryParams.get('form') === 'open';
  const [showForm, setShowForm] = useState(formOpen);

  const uid = queryParams.get('user_id'); // Assuming the URL is like /org-survey?id=123

  const formURL = `https://p19teneskuk.typeform.com/to/F9LvOFHg#user_id=${uid}`;

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const backgroundStyle = {
    height: '100vh',
    width: '100vw',
    background: `url(${backgroundOceanImage}) no-repeat center center fixed`,
    backgroundPosition: 'center', // Center the image
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden'
  };

  // You can leave your existing handleMouseEnter and handleMouseLeave functions as they were
  const handleMouseEnter = () => {
    if (!showForm) { // Only change text if form is not shown
      setIsHovered(true);
      setTimeout(() => setHoveredText("LET'S SAVE THE OCEAN"), 260);
    }
  };

  const handleMouseLeave = () => {
    if (!showForm) { // Only change text back if form is not shown
      setIsHovered(false);
      setTimeout(() => setHoveredText(""), 260);
    }
  };


  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) { // If the form is about to be shown, set the text immediately
      setHoveredText("HELP US HELP YOU");
    } else { // If the form is about to be hidden, clear the text
      setHoveredText("");
    }
  };
  
 
  const textStyle = {
    marginTop: '15%',
    width: '35%',
    height: 'auto',
    position: 'relative',
    left:'-1.5%',
    top: '-10%',
  };

  const buttonStyle = {
    padding: '12px 24px',
    letterSpacing: '0.05em', // Adjust as needed for desired spacing
    mixBlendMode: 'normal', // 'multiply', // Apply the soft light blend mode
    backgroundColor: 'rgba(135,210,219,0.9)', // mixBlendMode: options darken, lighten, color-dodge, color-burn
    color: '#87D2DB',//'#07384e',
    border: 'none',
    fontSize: '30px',
    fontFamily: 'Lato, sans-serif',
    cursor: 'pointer',
    marginTop: '-40px',
    width: '435px', // Example width; adjust as necessary
    borderRadius: '15px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.3)', // Shadow effect
    transform: 'translateY(0)', // Ensure it starts from a visible position
    transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for hover effects
    animation: !isMobile ? 'slideIn 2.5s cubic-bezier(0.8, 0, 0.4, 1) forwards' : 'none',
    minWidth: 'max-content', // Ensure the button is as wide as the longest text content
    width: 'auto', // Adjust to 'auto' and control padding to handle size
    padding: '32px 200px', // Increase padding as needed to fit longer text
};
  
  const paragraphStyle = {
    color: '#C7F5F9',
    //fontSize: '1.1em', // Makes the text larger. Adjust as needed.
    fontWeight: '500', // Makes the font weight lighter. Use '100' to '400' as desired.
    // Include other style properties as needed
  };

  const beforeStyle = `
  li::before {
    content: '• ';            // Bullet point character
    color: '#0077CC';         // Color of the bullet point
    display: inline-block;    // Needed to add content
    margin-left: -1.5em;      // Pull the bullet back to the left edge
    width: 1.5em;             // Space for the bullet point
    text-align: right;        // Align the bullet to the right of its block
    vertical-align: top;      // Align the bullet to the top of the text
  }
  `;
  const missionTextStyle = {
    position: 'relative',
    top: '-5%',
    width: '565px',
    color: '#C7F5F9',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    textShadow: '5px 5px 10px rgba(0, 0, 0, 0.4)', // Adds the drop shadow
  };
 

  const headingStyle = {
    letterSpacing: '0.1em', // Adjust as needed for desired spacing
    fontWeight: '500' // This sets the font weight to medium

    // You can add other styles here if necessary
  };
  const dotsStyle = {
    display: 'flex',
    color: '#C7F5F9',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px', // Adjust the height as needed
    margin: '20px auto', // Centers the dots and adds space above and below
  };
  
  const dotStyle = {
    height: '5px', // Diameter of the dot
    width: '5px', // Diameter of the dot
    backgroundColor: '#C7F5F9', // Color of the dots
    borderRadius: '50%', // Makes the dots circular
    margin: '0 5px', // Spacing between the dots
  };
  const buttonTextStyle = {
    mixBlendMode: 'normal',
    position: 'absolute',
    top: '50%', // Centers vertically
    left: '50%', // Centers horizontally
    transform: 'translate(-50%, -50%)', // 
    color: '#09394E',  // Ensuring a solid color, adjust as needed
    opacity: 1,  // Ensures the text is fully opaque
    //textShadow: '2px 2px 3px rgba(0, 0, 0, 0.6)', // Adjust the color and size as needed
    transition: 'opacity 0.5s ease', // Smooth the transition of text
    opacity: isHovered ? 0 : 1, // Fade out when hovered
    whiteSpace: 'nowrap', // Add this to prevent wrapping

    // Add any additional styling for the text here
  };
  const hoveredTextStyle = {
    ...buttonTextStyle, // Inherits the base styles

    // ... existing styles ...
    transition: 'opacity 0.5s ease', // Smooth the transition of text
    opacity: isHovered ? 1 : 0, // Fade in when hovered
    position: 'absolute', // Overlay this text on top of the other
  };
  const containerStyle = {
    position: 'relative', // This creates a positioning context for absolute elements
    // ... other styles ...
  };
  // Inside the Footer component
    const footerStyle = {
        // ...other styles...
        marginTop: '0', // Ensure the Footer has no top margin
    };

    const responsiveStyles = `
    @media (min-width: 768px) { /* Adjusts styles for screens wider than 768px */
    .responsive-logo {
        width: 65%; /* Makes the logo smaller on larger screens (70% smaller) */
        margin-top: -20px; /* Reduce top margin to bring the logo closer to the top */
        position: relative; /* Ensure this element can use relative positioning */
  
    }
   
    .responsive-heading { /* Adjust text styles for mobile screens */
    font-size: 1.3em; /* Smaller font size for mobile */
        }
    .responsive-paragraph { /* Adjust text styles for mobile screens */
        font-size: 0.9em; /* Smaller font size for mobile */
        }
    }

    @media (max-width: 768px) { /* Adjusts styles for screens up to 768px wide */
    .responsive-logo {
        width: 120%; /* Adjust the width as necessary for mobile screens */
    }
    .responsive-heading { /* Adjust text styles for mobile screens */
        font-size: 1.1em; /* Smaller font size for mobile */
    }
   
   
    .responsive-paragraph { /* Adjust text styles for mobile screens */
        font-size: 0.95em; /* Smaller font size for readability on mobile */
        margin: 0 auto; /* Center the paragraph within the missionTextStyle div */
        width: 75%;
        padding: 0 10px; /* Initial padding to create space inside */
        box-sizing: border-box; /* Include padding in width calculation */
        display: block; /* Ensures block level element behavior */
    }
    .responsive-button-text { /* Adjust text styles for mobile screens */
        font-size: 0.74em; /* Smaller font size for readability on mobile */
       
    }
}

`;
    const buttonText = showForm ? "HELP US HELP YOU" : "TAKE SURVEY";
    
  return (
    <div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
    <div style={backgroundStyle}>
        <style>
        {`
            @keyframes slideIn {
            from {
                transform: translateY(100vh);
            }
            to {
                transform: translateY(0);
            }
            }
        `}
        </style>

        <style>
        {responsiveStyles}
        {beforeStyle}
        </style>
        {isMobile ? (
          <HamburgerMenu currentSection={"marineOrganisations"} />
        ) : (
          <Navbar current="marineOrganisations" activeSection={currentSection} buttonColor ={'#87D2DB'} />
        )}

            <img src={textImage} alt="Centered Text" className="responsive-text" style={textStyle} />
        <div style={missionTextStyle}>
            <h2 className="responsive-heading" style={headingStyle}>HELP US HELP YOU</h2>
            
            <div style={dotsStyle}>
                <span style={dotStyle}></span> {/* First dot */}
                <span style={dotStyle}></span> {/* Second dot */}
                <span style={dotStyle}></span> {/* Third dot */}
            </div>

            <p className="responsive-paragraph" style={paragraphStyle}>
                Your Devocean is building a platform to unite and empower all marine organisations globally.
                <br/> <br/>
                We are bringing hands & funds to support each cause; together we can regenerate our beloved oceans.
                <br/><br/><br/><br/>
              
            </p>
                
        </div>
        <button 
            onClick={toggleForm} 
            style={{ 
            ...buttonStyle,
            pointerEvents: showForm ? 'none' : 'auto' // Prevents clicking when the form is shown
            }} 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
        >
            <div style={containerStyle}>
            <span style={buttonTextStyle}>TAKE SURVEY</span>
            <span style={{...hoveredTextStyle, opacity: isHovered || showForm ? 1 : 0}}>{hoveredText}</span>
            </div>
        </button> 
        <p className="responsive-paragraph" style={paragraphStyle}>
                <i className="responsive-button-text" style={{display: 'block', margin: '0 auto 10px', marginTop: '10px', textAlign: 'center', width: isMobile ? "100%" :"50%", fontSize: '0.85em', fontWeight: '400'}}>
                    This survey is designed for you to tell us how we can build a product that helps your organisation in doing more of what you do best.
                </i>
            </p>
        </div>

        {showForm && (
            <TypeformEmbed
            url={formURL}
            style={{ width: '100%', height: 'auto', minHeight: '500px', marginBottom: '-20px' }} // Adjust the marginBottom if necessary
            />
        )}
        
        
        <Footer  /> {/* Using Footer component here */}
    
    </div>
  );
};

export default SurveyPage;
