import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import iceImage from '../../web2_images/impact_03.jpg'; // Adjust path as necessary
import icon_cycling from '../../web2_images/icon_cycling.png';
import icon_ecosystem from '../../web2_images/icon_ecosystem.png';
import icon_resource from '../../web2_images/icon_resource.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
const isMobile = window.innerWidth <= 768;
gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  z-index:400;
  background: url(${iceImage}) center center / cover no-repeat;
  background-attachment: fixed;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: #285C70;
  overflow: hidden; 
  @media (max-width: 768px) {
    height: auto;
  }
`;

const One = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const OneA = styled.div`
  font-size: 5rem;
  font-weight: 900;
  font-size: 180px;
  color: #C7F5F9;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 100px;
  }
`;

const OneB = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #6DD3DC;
  text-align: left;
  margin-top: -0.5rem;
  width: 30%;
  @media (max-width: 768px) {
    width:70%;
  }
`;

const Two = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin-top:90px;
  margin-bottom:110px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    margin-top:40px;
  }
`;

const SetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  text-align: center;
  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const SetImage = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
`;

const SetTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #6DD3DC;
  margin-bottom: 10px;
`;

const SetText = styled.div`
  font-size: 19px;
  font-weight: 400;
  width:70%;
  text-align:left;
  color: #C7F5F9;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ImpactPageTwo = () => {
  const oneBRef = useRef(null);
  const setTextRefs = useRef([]);
  setTextRefs.current = [];
  const addToRefs = (el) => {
    if (el && !setTextRefs.current.includes(el)) {
      setTextRefs.current.push(el);
    }
  };
  useGSAP(() => {
    // Create the timeline for sequential animations
    const tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: oneBRef.current,
        start: 'top 80%', // Start the animation when the top of the section reaches 80% of the viewport height
        end: 'bottom top', // End the animation when the bottom of the section reaches the top of the viewport
        onEnter: () => tl.play(),
        onLeaveBack: () => tl.restart().pause(), // Reset and pause the timeline when scrolling back above the section
      },
    });

    // Animate the first element (oneBRef)
    tl.fromTo(
      oneBRef.current,
      { y: 50, opacity: 0 }, // Start from 50px below and fully transparent
      { y: 0, opacity: 1, duration: 1.5, ease: 'power2.out' }
    );

    // Animate the SetText elements sequentially
    setTextRefs.current.forEach((ref, index) => {
      tl.fromTo(
        ref,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 1.5, ease: 'power2.out' },
        index * 0.7 // Adjust the delay between animations
      );
    });
  }); 
  return (
    <SectionContainer id="impact3">
      <One>
        <OneA>10-35x</OneA>
        <OneB ref={oneBRef}>Integrating biodiversity conservation efforts into the carbon offset strategies will increase the carbon capture rates</OneB>
      </One>
      <Two>
        <SetContainer>
          <SetImage src={icon_resource} alt="Resource Efficiency" />
          <SetTitle>RESOURCE EFFICIENCY</SetTitle>
          <SetText ref={addToRefs}>Diverse ecosystems utilize resources more efficiently, leading to higher primary productivity.</SetText>
        </SetContainer>
        <SetContainer>
          <SetImage src={icon_ecosystem} alt="Ecosystem Stability" />
          <SetTitle>ECOSYSTEM STABILITY</SetTitle>
          <SetText ref={addToRefs}>Biodiverse systems are more resilient to environmental disturbances, ensuring sustained carbon sequestration over longer periods.</SetText>
        </SetContainer>
        <SetContainer>
          <SetImage src={icon_cycling} alt="Nutrient Cycling" />
          <SetTitle>NUTRIENT CYCLING</SetTitle>
          <SetText ref={addToRefs}>High biodiversity enhances nutrient cycling, which supports the growth and maintenance of carbon-sequestering plants and algae.</SetText>
        </SetContainer>
      </Two>
    </SectionContainer>
  );
};

export default ImpactPageTwo;
