import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import shorelineImage from '../../web2_images/landing_01.jpg';

import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation, useNavigate } from 'react-router-dom';
import landing_intro_image from '../../web2_images/landing_intro-text.png'

const Section = styled.section`
  height: 100vh;
  width: auto;
  font-family: Lato, sans-serif;
  background: url(${shorelineImage}) bottom center / cover no-repeat; // Directly use the imported image
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;

const Logo = styled.img`
  width: 25rem;
  height: auto;
  position: relative;
  left: -6.0%;
  top: -3%;

  @media (max-width: 768px) {
    margin-top: 15%;
    width: 18rem;
    left: -3.0%;
    top: 6%;
  }

`;

const Heading = styled.h2`
  letter-spacing: 0.15em;
  font-weight: 900;
  color: #07384e;
  position: relative;
  font-size: 0.8rem;
  left: 1.5%;
  margin-top: 4rem;
  margin-bottom: 1.4rem;
  top: 0%;

  @media (min-width: 769px) {
    letter-spacing: 0.2em;
    font-size: 1.0rem;
    left: 0%;
    margin-top: 0;
    margin-bottom: 1.0rem;
    top: 0;
  }
`;

const MotherText = styled.div`
  color: #07384e;
  font-size: 1.3rem;
  position: relative;
  width: 48%;
  margin-top:3.2rem;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 1.1rem;
    font-weight:700;
    text-align: left;
    margin-top:2rem;
    margin-bottom: -2rem;

  }

`;

const SaveText = styled.div`
  top: -7.0rem;
  mix-blend-mode: soft-light;
  position: relative;
  right: 7rem;
  color: #ffffff;
  font-size: 6.2rem;
  font-weight: 900;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3rem;
    top: -4.5rem;
    right:0rem;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 8rem;
  }
  @media (min-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
`;

const TextContainer = styled.div`

  @media (min-width: 768px) {
    margin-left: 12rem;
    margin-top: 14rem;
  }

  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

`
const MobileParagraphs = styled.div`
  
    display: none;

`;

const IntroImage = styled.img`
  width: 400px;
  margin-bottom: -5%;
  margin-left: -1%;

  @media (max-width: 768px) {
    display: none;
  }
`

const Button = styled.a`
  border: 4px solid #C7F5F9; /* Same turquoise color */
  background: transparent;
  color: #C7F5F9; /* Same turquoise color */
  padding: 0.1rem 0; /* Adjusted padding */
  margin: 0 0.2rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 250px; /* Fixed width */
  display: inline-block; /* Ensures the element respects the width */

  &:hover {
    background: #87D2DB; /* Turquoise background on hover */
    color: white; /* White text on hover */
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;


const LandingOne = ({ isMobile, logoImage, setLogoRef }) => {
  const logoRef = useRef(null);
  const oceanLife = useRef(null);
  
  const buttonContainerRef = useRef(null);
  const impactButtonRef = useRef(null);
  const offeringButtonRef = useRef(null);
  const introImageRef = useRef(null);


  const navigate = useNavigate();
  
  
  const navigateTo = (page) => {
    navigate(page);
  }

  useEffect(() => {
    setLogoRef(logoRef);
  }, [setLogoRef]);


  useGSAP(() => {
  
    gsap.fromTo(
      oceanLife.current,
      { y: '-14rem', opacity: 0 },
      {
        y: '6rem',
        opacity: 1,
        scrollTrigger: {
          trigger: oceanLife.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      }
    );

    const tl = gsap.timeline({delay:1.5});
    tl.fromTo(
      impactButtonRef.current,
      { y: '15vh', opacity: 0 },
      { y: '0', opacity: 1, duration: 2, ease: 'power2.out' }
    ).fromTo(
      offeringButtonRef.current,
      { y: '15vh', opacity: 0 },
      { y: '0', opacity: 1, duration: 1.6, ease: 'power2.out' },
      '-=1' // Overlap the animations by 0.5 seconds
    );
  }, []);

  return (
    <Section id="section1" backgroundImage={shorelineImage}>
      <TextContainer>
      {isMobile && (
          <>
            <Logo src={logoImage} ref={logoRef} alt="logo" />
            <Heading>UNITE | EMPOWER | IMPACT</Heading>
          </>
        )}
        <IntroImage  ref={introImageRef} src={landing_intro_image} alt="logo" />
        <MotherText>
        We are building a digital platform that helps ocean-focused
        organisations regenerate the waters of our world & empowers companies
        and people to collaborate in the effort. <br/> <br/>Join us as we unite an ecosystem of stakeholders who each hold a key
        to restoring our oceans to fully biodiverse health. <br/><br/>
        <b> Let's save our Mother</b> <br/><br/>
         </MotherText>
        <ButtonContainer ref={buttonContainerRef}>
          <Button ref={impactButtonRef} onClick={()=>navigateTo("/impact")}>IMPACT</Button>
          <Button ref={offeringButtonRef} onClick={()=>navigateTo("/offering")}>SOLUTION</Button>
        </ButtonContainer>
      </TextContainer>
    </Section>
  );
};

export default LandingOne;
