import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import backgroundImage from '../../web2_images/yd_solution02.jpg';
import stoneImage from '../../web2_images/solution_02.jpg';
 
import heartsImage from '../../web2_images/solution_headline.png';
import heartsImage2 from '../../web2_images/solution_headline-mob.png';
import finalImage from '../../web2_images/yd_solution02addon.jpg';

const OuterContainer = styled.section`
  width: 100%;
  overflow-x: hidden; // Prevent horizontal scrolling
  overflow-y: hidden;
`;


const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 66vh;
  display: flex;
  flex-direction: column;
  align-items: center; // Center align horizontally
  justify-content: center; // Center align vertically
  text-align: center;
  color: white;
  overflow-y: hidden;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 40px;
    height: 66vh;
    box-sizing: border-box; // Include padding in width calculation
  }
`;
const BackgroundImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:66vh;
  background: url(${backgroundImage}) bottom center / cover no-repeat;
  background-attachment: fixed; // Ensures the background image is fixed
  background-position: bottom top;

  z-index: -1;
  transform: translateY(0); // Start with no translation

  @media (max-width: 768px) {
    height: 66vh;
  }

`;

const Text = styled.div`
  position:relative;
  font-family: Lato, sans-serif;
  color: white;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 0rem;
  text-align: left;
  width: 50%;
  right: 15%;
  top: 18%;

    @media (max-width: 768px) {
        width: 90%;
        right: 0%;
        top:0%;
        font-size:1.00rem;
        color:white;
        margin-top: 130px;
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    }
`;

const Modal = styled.div`
  position: absolute;
  left: 25%;
  bottom: -10rem;
  transform: translate(-50%, -50%);
  font-family: 'Lato', sans-serif;
  background: #08424C;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
  max-width: 200px;
  text-align: left;
  opacity: 0; // Initial state
  transform: translate(-50%, -60%); // Initial state
  transition: opacity 0.6s ease, transform 0.6s ease; // Transition properties

  &.show {
    opacity: 1; // Visible state
    transform: translate(-50%, -50%); // Visible state
  }

  @media (max-width: 768px) {
    left: 50%;
  }
`;

const Modal2 = styled(Modal)`
    left: 75%;
    @media (max-width: 768px) {
        left:50%;
        z-index:1300;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const HeartsImage = styled.img`
  position: relative;
  top: 25%; // Adjust this value to move the image down from the center
  width: 60%;
  right: 15%;
  z-index: 31;
  animation: ${fadeIn} 2s forwards; // Apply the fade-in animation

    @media (max-width: 768px) {
        width: 110%;
        right:0%;
        top:-5%;
    }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;


const StoneSection = styled.div`
  width: 100%;
  height: 66vh;
  background: url(${stoneImage}) bottom center / cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 36vh;
    padding: 40px 20px;
  }
`;

const StoneText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  font-weight: 200;
  color: #C7F5F9;
  justify-content: center;
`;

const StoneItems = styled.div`
display: flex;
flex-direction: row;
`

const StoneTextItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px;
  padding: 50px;
  font-size: 1.3rem;
  color: #C7F5F9;
  font-weight:200;
  @media (max-width: 768px) {
    padding:0px;
    font-size: 1rem;
    margin: 10px 10px;
  }
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  margin: 20px 10px;
`;

const FinalImage = styled.img`
    width: 100%;
    height: 80%;
    margin-bottom: -20px;
    opacity:0; 
    @media (max-width: 768px) {
        opacity:0;
    }
`;
const Solve = styled.div`

    @media (max-width: 768px) {
        margin-top: -40px;
        font-size: 0.83rem;
        font-weight: 200;
        margin-bottom: 20px;
    }
`;
const DuplicateBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; // Full height to cover the screen
  background: url(${backgroundImage}) bottom center / cover no-repeat;
  background-attachment: fixed; // Ensures the background image is fixed
  background-position: bottom top;
  z-index: -2; // Lower z-index to place it behind other content
  opacity: 1; // Start with the duplicate background hidden

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

const SolutionPageOne = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModal2Open, setModal2Open] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const backgroundImageRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
   
  }, []);


  const handleClose = () => {
    setModalOpen(false);
  };
  const handle2Close = () => {
    setModal2Open(false);
  };


  return (
    <OuterContainer>

      <PageContainer>
      <DuplicateBackground />

        <BackgroundImage ref= {backgroundImageRef}/> 
        {isMobile ? <HeartsImage src = {heartsImage2}/> :  <HeartsImage src = {heartsImage}/>}
        <Text>We reshape the market through a platform that empowers companies and individuals to boost ocean regeneration at scale. We bring hands & funds to support ocean organisations with transparent, impactful, and unified action.</Text>
      </PageContainer>

      <StoneSection>
        <StoneText>
          <Solve>To solve one of the greatest existential threats, we foster alignment of</Solve>
          <StoneItems>
          <StoneTextItem>
            TECH 
            <Dot />
            FUNDS
          </StoneTextItem>
          <StoneTextItem>
            HEARTS
            <Dot />
            MINDS
          </StoneTextItem>
          <StoneTextItem>
            ACTION
            <Dot />
            NATURE
          </StoneTextItem>
        </StoneItems>
        </StoneText>
      </StoneSection>

      <FinalImage src = {finalImage}></FinalImage>
      {isModalOpen && (
        <>
          <Overlay onClick={handleClose} />
          <Modal className={isModalOpen ? 'show' : ''}>
          Activities led by ocean organisations together with the conscious community of volunteers. These enhance biodiversity and increase the carbon sequestration capacity of the ecosystems 10-35X.
Examples: Plastic removal above & below, nurturing species, habitat protection & regeneration, coral gardening, and research above & below.
          </Modal>
        </>
      )}
       {isModal2Open && (
        <>
          <Overlay onClick={handle2Close} />
          <Modal2 className={isModal2Open ? 'show' : ''}>
          Verified projects with  measurable, high carbon sequestering capacity at scale. Performed by ocean organisations and local communities. These generate standardised blue carbon tokens for your balance.
Examples: Plantation of  mangroves, salt/tidal marshes & seagrass meadow (kelp to come).
          </Modal2>
        </>
      )}
    </OuterContainer>
  );
};

export default SolutionPageOne;
