const TypeformEmbed = ({ url, style }) => {
    return (
      <iframe
        src={url} // Embed URL from Typeform
        style={style}
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        title="Typeform"
      ></iframe>
    );
  };
  
  export default TypeformEmbed;