import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ current, activeSection, buttonColor }) => {
    const navbarStyle = {
        position: 'fixed',
        top: '2%',
        right: '0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        zIndex: 1000,
    };
    const navigate = useNavigate();

    let adjustedButtonColor = activeSection === ('section2')  || (activeSection ===  'section4') || (activeSection ===  'section3') || (activeSection ===  'section5')  ? '#87D2DB' : buttonColor || '#08424C';

    if (current == 'offering'  || current == 'impact' || current == 'team') {
        adjustedButtonColor = '#87D2DB';
    }

    const circlesBaseStyle = {
        top: '52%',
        right: '340px',
        marginTop: '115px',
        marginRight: '42%',
        transform: 'translateY(-50%)', // Center vertically
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
    };

    const circleBaseStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        marginBottom: '10px',
        border: '2px solid ' +adjustedButtonColor, 
        cursor: 'pointer',
        backgroundColor: 'transparent', // '#87D2DB', // Default color
        transition: 'background-color 0.5s, border-color 0.5s' // Smooth transition

    };

    const activeCircleStyle = {
        ...circleBaseStyle,
        backgroundColor: adjustedButtonColor, // Active color
    };

    const verticalLineStyle = {
        width: '20px',
        height: '5rem',
        backgroundColor: '#87D2DB',
        marginTop: '-55px',
        marginBottom: '40px',
        marginRight: '40%',
    };

    const buttonBaseStyle = {
        fontFamily: 'Lato, sans-serif',
        fontSize: '1rem',
        fontWeight: '900',
        background: 'none',
        width: '150px',
        border: 'none',
        cursor: 'pointer',
        marginBottom: '40px',
        marginRight: '37%',
        textAlign: 'right',
        textDecoration: 'none', // Remove underline for links
        transition: 'color 0.5s', // Smooth transition
    };

    const activeButtonStyle = {
        ...buttonBaseStyle,
        color: '#C7F5F9',
    };

    const inactiveButtonStyle = {
        ...buttonBaseStyle,
        color: adjustedButtonColor //buttonColor || '#07384E',
    };

    const bottomLineStyle = {
        position: 'fixed',
        bottom: '0px',
        right: '0%',
        width: '20px',
        height: '5rem',
        backgroundColor: '#87D2DB',
        marginRight: '60px',
    };

    const handleNavigation = (sectionId) => {
        if (window.location.pathname === '/') {
            window.location.hash = sectionId;
        } else {
            navigate('/#' + sectionId);
        }
    };

    const sections = current === 'consciousConsumers' ? ['section1', 'section2', 'section3', 'section4', 'section5'] : [];
    
    return (
        <div style={navbarStyle}>
            <div style={verticalLineStyle}></div>

            <a
                style={current === 'consciousConsumers' ? activeButtonStyle : inactiveButtonStyle}
                href="/#section1"
            >
                HOME
            </a>
            
            <a
                style={current === 'impact' ? activeButtonStyle : inactiveButtonStyle}
                href="/impact#impact1"
            >
                IMPACT
            </a>
            
            <a
                style={current === 'offering' ? activeButtonStyle : inactiveButtonStyle}
                href="/offering#offering1"
            >
                SOLUTION
            </a>
            
            <a
                style={current === 'team' ? activeButtonStyle : inactiveButtonStyle}
                href="/team"
            >
                ABOUT US
            </a>
            
            <a
                style={current === 'surveys' ? activeButtonStyle : inactiveButtonStyle}
                href="/surveys"
            >
                SURVEYS
            </a>
            
            <div style={circlesBaseStyle}>
                {sections.map((section) => (
                    <a
                        key={section}
                        href={`#${section}`}
                        style={activeSection === section ? activeCircleStyle : circleBaseStyle}
                    />
                ))}
            </div>
            <div style={bottomLineStyle}></div>
        </div>
    );
};

export default Navbar;
