import React, { useRef, useEffect } from 'react';
import TypeformEmbed from '../TypeformEmbed'; // Import your Typeform component

const SignupModal = ({ isOpen, onClose, type_form_url ="https://p19teneskuk.typeform.com/customer-survey#user_id=xxxxx" }) => {
  const modalRef = useRef(null);
  const isMobile = window.innerWidth < 768;

  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%', // Ensure the overlay spans the full width
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1050, // Ensure the z-index is high enough
  };

  const modalContentStyle = {
    background: '#07384E',
    borderRadius: '10px',
    boxShadow: '2px 2px 20px rgba(0,0,0,0.3)',
    position: 'relative',
    width: '90%',
    maxWidth: '700px',
    padding: '0', // Remove padding to ensure full survey width
  };

  const modalContentMobileStyle = {
    ...modalContentStyle,
    width: '100%',
    height: '100%', // Ensure it covers full height on mobile
    borderRadius: '0px',
  };

  const headlineStyle = {
    color: '#C7F5F9',
    fontSize: '2rem',
    marginBottom: '10px',
    textAlign: 'center',
    padding: '10px',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    background: 'none',
    fontSize: '1.5rem',
    color: '#C7F5F9',
    cursor: 'pointer',
  };

  const handleClickOutside = (event) => {
    // if (modalRef.current && !modalRef.current.contains(event.target)) {
    //   onClose();
    // }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>

      <div style={isMobile ? modalContentMobileStyle : modalContentStyle} ref={modalRef}>
        <button style={closeButtonStyle} onClick={onClose}>×</button>
        <TypeformEmbed
          url={type_form_url}
          style={{ width: '100%', height: '100%', minHeight: '500px' }}
        />
      </div>
    </div>
  );
};

export default SignupModal;
