// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPageStatic from './pages/LandingPageStatic';
import ConsciousConsumers from './pages/ConsciousConsumers';
import PartnerPage from './pages/PartnerLandingPage';
import SurveyPage from './pages/SurveyPage';
import CorporatePage from './pages/CorporatePage';
import ImpactLandingPage from './pages/ImpactLandingPage';
import TeamLandingPage from './pages/TeamLandingPage';
import SurveyLandingPage from './pages/SurveyLandingPage';

import SmoothScrolling from './pages/SmoothScrolling';


const App = () => {
  return (
    <SmoothScrolling>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPageStatic />} />
        <Route path="/org-survey" element={<SurveyPage />} />
        <Route path="/offering" element={<PartnerPage />} />
        <Route path="/impact" element={<ImpactLandingPage />} />
        <Route path="/team" element={<TeamLandingPage />} />
        <Route path="/surveys" element={<SurveyLandingPage />} />
      </Routes>
    </Router>
    </SmoothScrolling>
  );
};

export default App;