import React from 'react';
import styled from 'styled-components';
import iceImage from '../../web2_images/team_01.jpg'; // Adjust path as necessary
import overlayImage from '../../web2_images/team_web.png'; // Import the overlay image
import teamMobile from '../../web2_images/team_web-mob.png';

const OuterContainer = styled.div`
  width: 100%;
  overflow-x: hidden; // Prevent horizontal scrolling
  overflow-y: hidden;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: row; // Change to row for side-by-side layout
  justify-content: flex-start; // Align items at the top
  align-items: stretch; // Stretch to fit height
  height: 100vh;
  background: url(${iceImage}) center center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow: hidden;
  position: relative; // Make it a positioned element for absolute positioning of the overlay

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column; // Change direction to column on mobile
    align-items: flex-start; // Align items to the start
    background: url(${iceImage}) center center / cover no-repeat;

  }
`;

const ABOUT = styled.div`
  flex: 0 0 650px; // Fixed width for ABOUT section
  background: rgba(8, 66, 76, 1.0); // Semi-transparent background to show iceImage
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-left: 150px; // Margin to show part of the iceImage
  order: 1; // Default order for desktop

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0; // Remove margin for mobile
    background: #08424C; // Solid background for mobile
    order: 2; // Change order for mobile
  }
`;

const TEAM = styled.div`
  flex: 1;
  position: relative; // Allow OverlayImage to be positioned absolutely
  order: 2; // Default order for desktop

  @media (max-width: 768px) {
    width: 100%;
    order: 1; // Change order for mobile
  }
`;

const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1; // Higher than the background, lower than the logo
`;

const OverlayImage = styled.img`
  position: relative;
  top: 0;
  right: 0;
  width: 91%;
  right: 210px;
  object-fit: cover;
  margin-top: 160px; // Adjust margin to center the image vertically

  @media (max-width: 768px) {
    position: relative;
    margin-top:50px;
    width: 60%;
    right: 0px;
    margin-bottom:30px;
    background: url(${teamMobile}) center center / cover no-repeat;
    
  }
`;

const TextBox = styled.div`
  font-size: 1.1vw;
  padding: 20px;
  color: white;
  width: 68%;
  z-index: 2; // Higher than the overlay image
  text-align: left;
  border-radius: 10px; // Rounded corners
  margin-right: 10%;
  font-weight:300;

  h1, h2, h3 {
    color: white;
  }

  b {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 4vw; // Larger font size for mobile
    margin-top: 20px; // Add margin to separate from overlay image
    padding: 10px; // Adjust padding for mobile
    margin-right: 0%;

  }
`;

const Header = styled.div`
  font-size: 1.0rem;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 20px;
  color: #C7F5F9;
`;

const TeamOne = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <OuterContainer>
      <SectionContainer id="team1">
        <ABOUT>
          <TextBox>
            <Header>ABOUT US</Header>
            <p>We are a passionate Stockholm-based startup that began our journey in April of 2024. Our team is composed of tech-savvy ocean lovers who are deeply committed to saving the oceans and preserving life on our planet. With ~80 years of blended experience, we bring a proven track record in building successful companies, leveraging cutting-edge AI technology, and creating digital beauty.</p>
            <p>Our balanced team dynamics, combining seasoned professionals with youthful drive, enable us to adapt swiftly to challenges while benefiting from strategic insight and intuition for what will work and what will not.</p>
            <p>We are empathic leaders, grounded in scientific understanding and a love for nature, ready to guide a scaled-up <i>Your Devocean</i>.</p>
            <p><i>Join us in our journey to heal and regenerate our oceans.<br/> Together, we can make a difference.</i></p>
          </TextBox>
        </ABOUT>
        <TEAM>
          <OverlayContainer>
            <OverlayImage 
              src={isMobile ? teamMobile : overlayImage} 
              alt="Overlay" 
            />
          </OverlayContainer>
        </TEAM>
      </SectionContainer>
    </OuterContainer>
  );
};

export default TeamOne;
