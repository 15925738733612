import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import iceImage from '../../web2_images/landing_04.jpg'; // Adjust path as necessary

import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import animationVideo from '../../web2_images/carbon_graph-04.mp4';

gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.div`
  height: 100vh;  
  justify-content: flex-start; 
  align-items: left;
  background: url(${iceImage}) top center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow: hidden; 
  z-index: 1;
  @media (max-width: 768px) {
    height:auto;
  }
`;

const Section = styled.section``;

const ItemsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start; /* Updated */
  font-family: Lato, sans-serif;
  text-align: left;
  color: white;
  z-index: 5;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;

    justify-content: center;
    flex-direction: column; // Adjust to column for better alignment on mobile
  }
`;

const Text = styled.p`
  margin-top: 7rem;
  font-size: 2.0rem;
  line-height: 1.5;
  margin-left:9rem;
  font-family: Lato, sans-serif;
  color: #C7F5F9;
  width: 100%;
  font-weight: 700;
  z-index: 5;
  opacity:0;

  @media (max-width: 768px) {
    font-size: 1.40rem;
    margin-left: 1.2rem;
    margin-top: 5rem;
    text-align: left;
    width: 90%;
  }

`;

const StyledVideo = styled.video`
  width:60vw;
  position:relative;
  z-index:5;
  mix-blend-mode: lighten;
  margin-top: 0.14rem;
  margin-left: 13rem;
  padding:4rem;
  opacity: 1;

  @media (max-width: 768px) {
    width: 94%;
    margin-left: 0;
    margin-top: 0rem;
  }
`;

const TextDiv = styled.div`
  height: 10rem;
`


const SmallerText = styled(Text)`
  font-size: 1.25rem;
  opacity:0;
  margin-top: -2rem;
  @media (max-width: 768px) {
    font-size: 1.05rem;
    margin-left: 1.2rem;
    margin-top: -1rem;
    text-align: left;
    width: 90%;
  }
`;


const LandingFour = () => {
  const videoRef = useRef(null);
  const textRef = useRef(null);
  const textRef2 = useRef(null);

  useGSAP(() => {
    const video = videoRef.current;
    const text = textRef.current;
    const text2 = textRef2.current;
   
    const resetTimeline = () => {
        timeline.seek(0).pause();
        timeline.invalidate(); // Force timeline to revalidate and update
        video.currentTime = 0; // Reset video to start
        text.innerText = 'Much is being done & more is on the horizon.';
        text2.style.opacity = 0; // Reset opacity of second text
      };

    const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: video,
          start: "top bottom", // Start the animation when the bottom of the videoRef element hits the bottom of the viewport
          toggleActions: "play restart play restart",
          onLeaveBack: resetTimeline,
          onLeave: resetTimeline
        },
      });
    
    timeline
      .to(text, { x: 0, opacity: 1, duration: 1, delay: 1 }) // Fade in the first headline
      .to(text, { x: -100, opacity: 0, duration: 1, delay: 3.5, onComplete: () => {
          text.innerText = "Yet we continue to build a backlog of CO₂ in our atmosphere & ocean.";
        }
      }) // Fade out the first headline and replace it with the second
      .to(text, { x: 0, opacity: 1, duration: 1 }) // Fade in the second headline
      .to(text, { x: -100, opacity: 0, duration: 1, delay: 6.5, onComplete: () => {
          text.innerHTML = `To make matters worse, `;
        }
      }) // Fade out the second headline and replace it with the third
      .to(text, { x: 0, opacity: 1, duration: 1 }) // Fade in the third headline
      .to(text2, { x: 0, opacity: 1, duration: 1, delay: 3.2 }); // Fade in the third headline

      // Add event listener for visibility change to handle session resume
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          resetTimeline(); // Reset and reinitialize the timeline
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };

  }, []);

  return (
    <Section id="section4">
      <SectionContainer>
        <ItemsDiv>
            <TextDiv>
            <Text ref={textRef}>Much is being done & more is on the horizon.</Text>
            <SmallerText ref={textRef2}>the emission legacy to capture for a healthy atmosphere dwarfs current progress</SmallerText>
            </TextDiv>
            <StyledVideo ref={videoRef} autoPlay muted playsInline>
                 <source src={animationVideo} type="video/mp4" />                
                Your browser does not support the video tag.
            </StyledVideo>
        </ItemsDiv>
      </SectionContainer>
    </Section>
  );
};

export default LandingFour;
