import React, { useState, useEffect, useRef } from 'react';

import iceImage from '../../web2_images/impact_01.jpg'; // Adjust path as necessary
import barImage from '../../web2_images/impact_01bar.png'; // Adjust path as necessary
import barImage2 from '../../web2_images/impact_02bar.jpg'; // Adjust path as necessary
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import styled, { keyframes } from 'styled-components';

gsap.registerPlugin(ScrollTrigger);

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 78vh;
  background: url(${iceImage}) center center / cover no-repeat;
  text-align: center;
  color: #285C70;
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const MiddlebarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 42vh;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Middlebar = styled.div`
  opacity: 1;
  background: url(${barImage}) no-repeat center center / cover;
  height: 49vh;
  width: 100%;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
`;


const TextContainer = styled.div`
  font-family: 'Lato', sans-serif;
  color: #C7F5F9;
  opacity:1;
  font-size: 1.8rem;
  z-index: 30;
  text-align: center; /* Ensure text is centered */
  @media (max-width: 768px) {
    width: 80%;
  
  }
`;

const TextOne = styled.div`
  font-size: 3.9rem;
  font-weight: 900;
  margin-top: 2rem;
  z-index: 3000;
  animation: ${fadeIn} 2s forwards; /* Apply the fade-in animation */


  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 0.2rem;
    
  }
`;

const TextTwo = styled.div`
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #87D2DB;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-top: 0.5rem;
  }
`;

const TextThree = styled.div`
  width: 41%;
  margin: 0 auto; /* Center the element horizontally */
  font-size: 1.2rem;
  font-weight:300;
  margin-top: 2rem;
  color: #87D2DB;
  @media (max-width: 768px) {
    width:80%;
    margin-top: 1.5rem;
  }
`;

const TextFour = styled.div`
    text-align: center;
    margin-left:34rem;
    font-size: 0.8rem;
    @media (max-width: 768px) {
        margin-left:0;
    }
`;

const SecondBit = styled.div`
    height: 50vh;
    background: url(${barImage2}) center center / cover;
    margin-top:-2.5rem;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
`;


const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  flex: 1;
  margin: 0 20px;
  @media (max-width: 768px) {
    margin: 10px 0;
    text-align:center;
  }
`;

const Column2 = styled(Column)`
  text-align: right;
  @media (max-width: 768px) {
    text-align:center;
  
  }
`;

const ColumnText = styled.div`
  font-family: 'Lato', sans-serif;
  color: #87D2DB;
  text-align: center;
  font-weight: 900;
  margin: 10px 0;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  @media (max-width: 768px) {
    ${({ mobileFontSize }) => mobileFontSize && `font-size: ${mobileFontSize};`}
  }
`;

const ColumnTextZero = styled(ColumnText)`
  text-align:center;
  margin-top: 4rem;

  @media (min-width: 768px) {
    text-align:right;
    position:absolute;
    margin-top: -0.5rem;
    margin-left:9rem;
  }

`;
const ColumnTextOne = styled(ColumnText)`
  text-align:right;
  @media (max-width: 768px) {
    text-align:center;
  }
`;

const ColumnTextTwo = styled(ColumnText)`
  text-align:right;
  color: #C7F5F9;

  @media (max-width: 768px) {
    text-align:center;
    font-size: 2.3rem;
  }
`;

const ColumnTextThree = styled(ColumnText)`
  margin-top:160px;
  font-weight:300;
  @media (max-width: 768px) {
    margin-top:10px;
    text-align:center;
  }
`;


const ColumnTextFour = styled(ColumnText)`
  margin-top:100px;
  text-align:left;
  font-weight:300;
  @media (max-width: 768px) {
    margin-top:10px;
    text-align:center;
  }
`;


const ImpactPageTwo = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const numberRef = useRef(null);
  const animationPlayed = useRef(false);

  useGSAP(() => {
    const updateNumber = (value) => {
      if (numberRef.current) {
        numberRef.current.textContent = Math.floor(value).toLocaleString();
      }
    };

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: numberRef.current,
        start: "bottom bottom",
        onEnter: () => {
          if (!animationPlayed.current) {
            console.log("Animation started");
            animationPlayed.current = true;
            timeline.play();
          }
        },
      },
    });

    timeline
      .to({}, {
        duration: 0.5,
        onUpdate: function () {
          const progress = this.progress();
          const value = progress * 1000;
          updateNumber(value);
        },
        ease: "power1.inOut",
      })
      .to({}, {
        duration: 0.6,
        onUpdate: function () {
          const progress = this.progress();
          const value = progress * (100000 - 1000) + 1000;
          updateNumber(value);
        },
        ease: "power2.inOut",
      })
      .to({}, {
        duration: 0.7,
        onUpdate: function () {
          const progress = this.progress();
          const value = progress * (1000000 - 100000) + 100000;
          updateNumber(value);
        },
        ease: "power3.inOut",
      })
      .to({}, {
        duration: 1,
        onUpdate: function () {
          const progress = this.progress();
          const value = progress * (100000000 - 1000000) + 1000000;
          updateNumber(value);
        },
        ease: "power4.inOut",
      });

  }, []);
  return (
    <div>
        <SectionContainer id="impact1">
        <MiddlebarContainer>
            <Middlebar>
            <TextContainer>
                <TextOne>BIODIVERSITY</TextOne>
                <TextTwo>[the variety of all living things and their interaction]</TextTwo>
                <TextThree>"... there will be no successful Nature Based Solutions without Biodiversity protection. So we need to incorporate biodiversity as underlying every single action we take... "</TextThree>
                <TextFour> - THE ECONOMIST</TextFour>
            </TextContainer>
            </Middlebar>
        </MiddlebarContainer>
        </SectionContainer>

        <SecondBit>
        {isMobile ? (
          <ColumnsContainer>
            <ColumnTextZero fontSize="50px" mobileFontSize="1.5rem">
              The Power <br /> of the Ocean
            </ColumnTextZero>
            <ColumnTextOne fontSize="200px" mobileFontSize="4rem">1%</ColumnTextOne>
            <ColumnTextThree fontSize="15px" mobileFontSize="0.75rem">
              increase of ocean’s carbon absorption capacity equals
            </ColumnTextThree>
            <ColumnTextTwo fontSize="70px" mobileFontSize="1.5rem" ref={numberRef}>0</ColumnTextTwo>
            <ColumnTextFour fontSize="1rem" mobileFontSize="0.75rem">cars' yearly emissions</ColumnTextFour>
          </ColumnsContainer>
        ) : (
        <ColumnsContainer>
          <Column>
            <ColumnTextZero fontSize="50px" mobileFontSize="1.5rem" >The Power <br/> of the Ocean</ColumnTextZero>
          </Column>
          <Column2>
            <ColumnTextOne fontSize="200px" mobileFontSize="4rem">1%</ColumnTextOne>
            <ColumnTextTwo fontSize="70px" mobileFontSize="1.5rem" ref={numberRef}>0</ColumnTextTwo>
          </Column2>
          <Column>
            <ColumnTextThree style ={{textAlign: 'left'}} fontSize="0.9rem" mobileFontSize="0.75rem">
              increase of ocean’s carbon <br/> absorption capacity equals
            </ColumnTextThree>
            <ColumnTextFour fontSize="0.9rem" mobileFontSize="0.75rem">
              cars' yearly emissions
            </ColumnTextFour>
          </Column>
        </ColumnsContainer>
        )}
        </SecondBit>
    </div>
  );
};

export default ImpactPageTwo;
