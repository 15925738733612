import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import FloatingButton from './FloatingButton';
import SignupModal from './SignupModal';

import facebookIcon from '../../landing_images/socials/fb-turq.png';
import instagramIcon from '../../landing_images/socials/ig-turq.png';
import linkedinIcon from '../../landing_images/socials/Li-turq.png';
import youtubeIcon from '../../landing_images/socials/you-turq.png';
import tiktokIcon from '../../landing_images/socials/tik-turq.png';
import logoImage from '../../web2_images/YD_logo_turq.png';

const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #08424C;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const NavButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`;

const NavLink = styled.a`
  font-family: 'Lato', sans-serif;
  font-size: 21px;
  font-weight: 900;
  color: #87D2DB;
  background: none;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  text-align: center;
  transition: color 0.5s;
  text-decoration: none; // Remove underline for links
  width: 90%; // Ensure full width
  max-width: 10rem; // Limit maximum width
  z-index: 1000;
  &:hover {
    color: #C7F5F9;
  }

  @media (max-width: 768px) {
    font-size: 1.0rem;
    margin-bottom: 50px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -8rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 20px;
`;

const LogoContainer = styled.div`
  margin-bottom: 1rem;
`;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem; // Adjust padding as needed
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0rem;
  background: none;
  border: none;
  font-family: 'Arial', sans-serif;
  font-weight: 100; /* Make the "X" thinner */
  color: #87D2DB;
  margin-top: 0.5rem;
  font-size: 3rem;
  cursor: pointer;
`;

const HamburgerMenu = ({ currentSection, activeSection }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  const activeButtonStyle = {width:'150px', color: 'white' };
  const inactiveButtonStyle = {width:'150px', color: '#87D2DB' };

  return (
    <>
      <div style={{ position: 'fixed', top: '20px', right: '20px', zIndex: '1000', cursor: 'pointer' }} onClick={handleMenuClick}>
        <div style={{ width: '30px', height: '3px', backgroundColor: activeSection === 'section1' || activeSection === 'section3' ? '#07384E' : '#87D2DB', margin: '6px 0' }}></div>
        <div style={{ width: '30px', height: '3px', backgroundColor: activeSection === 'section1' || activeSection === 'section3' ? '#07384E' : '#87D2DB', margin: '6px 0' }}></div>
        <div style={{ width: '30px', height: '3px', backgroundColor: activeSection === 'section1' || activeSection === 'section3' ? '#07384E' : '#87D2DB', margin: '6px 0' }}></div>
      </div>

      {menuOpen && (
        <NavbarContainer ref={menuRef}>
          <CloseButtonContainer>
            <CloseButton onClick={() => setMenuOpen(false)}>&times;</CloseButton>
          </CloseButtonContainer>

          <NavButtonsContainer>
            <NavLink
              style={currentSection === 'consciousConsumers' ? activeButtonStyle : inactiveButtonStyle}
              href="/#section1"
            >
              HOME
            </NavLink>
            <NavLink
              style={currentSection === 'impact' ? activeButtonStyle : inactiveButtonStyle}
              href="/impact#impact1"
            >
              IMPACT
            </NavLink>
            <NavLink
              style={currentSection === 'offering' ? activeButtonStyle : inactiveButtonStyle}
              href="/offering#offering1"
            >
              SOLUTION
            </NavLink>
            <NavLink
              style={currentSection === 'team' ? activeButtonStyle : inactiveButtonStyle}
              href="/team"
            >
              ABOUT US
            </NavLink>
            <NavLink
              style={currentSection === 'surveys' ? activeButtonStyle : inactiveButtonStyle}
              href="/surveys"
            >
              SURVEYS
            </NavLink>
          </NavButtonsContainer>

          <ButtonContainer>
            <FloatingButton animateOnLoad={false} onClick={() => setModalOpen(true)} />
            <SignupModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
          </ButtonContainer>
          <IconContainer>
            <a href="https://www.facebook.com/profile.php?id=61556798181929" target="_blank" rel="noopener noreferrer">
              <Icon src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/yourdevocean/" target="_blank" rel="noopener noreferrer">
              <Icon src={instagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/your-devocean/" target="_blank" rel="noopener noreferrer">
              <Icon src={linkedinIcon} alt="LinkedIn" />
            </a>
            <a href="https://www.youtube.com/@YourDevocean" target="_blank" rel="noopener noreferrer">
              <Icon src={youtubeIcon} alt="YouTube" />
            </a>
            <a href="https://www.tiktok.com/@yourdevocean" target="_blank" rel="noopener noreferrer">
              <Icon src={tiktokIcon} alt="TikTok" />
            </a>
          </IconContainer>
          <LogoContainer>
            <Logo src={logoImage} alt="logo" />
          </LogoContainer>

        </NavbarContainer>
      )}
    </>
  );
};

export default HamburgerMenu;
